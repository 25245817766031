import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router, Scroll} from '@angular/router';
import {
	combineLatest,
	debounceTime,
	filter,
	map,
	Observable,
	skip,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';
import { ShepherdService } from 'angular-shepherd';
import { offset } from '@floating-ui/dom';

import { Item, Part, Project, ProjectTree } from '~shared/types';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import { zeroPad } from '~shared/helpers';
import { ProjectsRepository } from '~modules/projects/store/projects/projects.repository';
import { OutlineSetsRepository } from '~modules/projects/store/outline-sets/outline-sets.repository';
import {
	BackComponent,
	CarcaseComponent,
	DoorComponent,
	DynamicDividerComponent,
	HorizontalDividerComponent,
	ItemComponent,
	OutlineFrontComponent,
	OutlineSideComponent,
	OutlineTopComponent,
	VerticalDividerComponent,
} from '~modules/projects/configuration-components';
import { ConfigurationItem } from '~modules/projects/components/editor-configuration/editor-configuration.component';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { AuthService } from '~core/services/auth.service';
import { UserRole, UserType } from '~modules/auth/types/user.types';
import { ArticleType, DesignerMode, ItemType } from '~shared/enums';
import {CuttingListLinesRepository} from "~modules/projects/store/cutting-list-lines/cutting-list-lines.repository";
import {CuttingListsRepository} from "~modules/projects/store/cutting-lists/cutting-lists.repository";

@Component({
	templateUrl: './editor.component.html',
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewInit {
	public project$: Observable<Project>;
	public projectParts$: Observable<Part[]>;
	public items$: Observable<Item[]>;
	public activeItem$: Observable<Item>;
	public activePart$: Observable<Part>;
	public fullscreen$: Observable<boolean>;
	public projectTree$: Observable<ProjectTree>;
	public configurationControllers: ConfigurationItem[] = [];
	public activeConfigurationPanel$: Observable<string>;
	public designerMode$: Observable<DesignerMode>;
	public showNewCabinetSelection: Record<string, boolean> = {};

	public openPart: string;
	public part: Part;
	public selectedItem: Partial<Item>;
	public selectedIndex: number = null;
	public loading = true;

	private componentDestroyed$: Subject<boolean> = new Subject();
	private parts: Part[];

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly itemsRepository: ItemsRepository,
		private readonly projectsRepository: ProjectsRepository,
		private readonly partsRepository: PartsRepository,
		private readonly outlineSetsRepository: OutlineSetsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly cuttingListLinesRepository: CuttingListLinesRepository,
		private readonly cuttingListRepository: CuttingListsRepository,
		private readonly shepherdService: ShepherdService,
	) {}

	// Get projects for side-navbar
	public ngOnInit(): void {
		this.editorRepository.setActiveConfigurationPanel(null);
		this.editorRepository.setPartEditorFullscreen(false);
		this.partsRepository.clearParts();
		this.itemsRepository.clearItems();
		this.itemsRepository.clearAllProjectItems();
		this.projectsRepository.clearProjects();
		this.cuttingListRepository.clearCuttingLists();
		this.cuttingListLinesRepository.clearCuttingListsLines();

		this.getProject();
		this.activeConfigurationPanel$ =
			this.editorRepository.activeConfigurationPanel$;
		this.fullscreen$ = this.editorRepository.partEditorFullscreen$;
		this.outlineSetsRepository.fetchOutlineSets();

		combineLatest([this.router.events, this.projectsRepository.project$])
			.pipe(takeUntil(this.componentDestroyed$)).subscribe(([event, project]) => {
			if ((event instanceof Scroll) && project && project.id) {
				try {this.shepherdService.complete();} catch (e) {}
				const url = event?.routerEvent?.url;
				this.selectDesignerModeByRoute(url);
			 }
		});

		this.projectsRepository.project$
			.pipe(
				filter((project) => !!project),
				take(1),
				tap((project) => {
					// this.authService.overwriteAccountId(project.accountId);
					const queryParams = this.route.snapshot.queryParams;

					if (queryParams.item) {
						this.itemsRepository.getItems([queryParams.part])
							.pipe(take(1))
							.subscribe()
						this.openPart = queryParams.part;
						return this.itemsRepository.activateItem(queryParams.item);
					}

					if (queryParams.part) {
						this.itemsRepository.getItems([queryParams.part])
							.pipe(take(1))
							.subscribe()
						this.openPart = queryParams.part;
						return this.partsRepository.activatePart(queryParams.part);
					}
				})
			)
			.subscribe();
		this.projectParts$ = this.partsRepository.parts$;

		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePart$ = this.partsRepository.activePart$;
		this.designerMode$ = this.editorRepository.designerMode$;

		combineLatest([this.projectsRepository.project$, this.authService.accountIdOverwrite$, this.authService.currentUser$]).pipe(
			takeUntil(this.componentDestroyed$)).subscribe(([project, accountId, user]) => {
				if ((project && project.accountId !== accountId) && !!user?.account && user.userType === UserType.INTERNAL) {
					this.router.navigate(['/', 'projects']);
				} else if ((project && project.accountId !== accountId) && !user ) {
					this.authService.overwriteAccountId(project.accountId, true);
				}
		});

		combineLatest([this.activeItem$, this.activePart$, this.editorRepository.selectedItems$])
			.pipe(
				skip(3),
				takeUntil(this.componentDestroyed$),
				tap(([item, part, [object]]) => {
					if (part) {
						return this.updateQueryParameters({
							object: null,
							item: null,
							part: part?.id || null,
						});
					}

					if (object) {
						return this.updateQueryParameters({
							item: item?.id || null,
							part: item?.partId || null,
							object: object?.id || null,
						});
					}

					if (item) {
						return this.updateQueryParameters({
							object: null,
							item: item?.id || null,
							part: item?.partId || null,
						});
					}
				})
			)
			.subscribe()

		this.loading = true;

		this.partsRepository.parts$.pipe(
			takeUntil(this.componentDestroyed$)).subscribe((parts: Part[]) => {
			this.parts = parts;
		});

		this.projectsRepository.project$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((project) => !!project),
				tap((project) =>
					this.partsRepository
						.getParts({ projectId: project.id })
						.pipe(take(1))
						.subscribe((parts) => {
							if (parts.length === 0) {
								this.addHintToProjectCreateBtn();
								return;
							}

							const queryParams = this.route.snapshot.queryParams;
							const partsIds = parts?.map((part: Part) => part.id);

							if (!queryParams.part) {
								this.itemsRepository.getAllProjectItems(partsIds, partsIds[0])
									.pipe(take(1))
									.subscribe()
								this.selectPart(parts[0]);
								this.openPart = parts[0]?.id;
								this.partsRepository.activatePart(parts[0]?.id)
							} else if (partsIds.length && queryParams.part) {
								this.itemsRepository.getAllProjectItems(partsIds)
									.pipe(take(1))
									.subscribe()
							}
						})
				),
				tap(() => (this.loading = false)),
				switchMap((project) =>
					this.authService.currentUser$.pipe(
						take(1),
						map((user) => ({ user, project }))
					)
				),
				filter(({ user }) => !!user),
				takeUntil(this.componentDestroyed$)
			)
			.subscribe(({ user, project }) => {
				if (user.userType === UserType.INTERNAL && !user.account) {
					this.authService.overwriteAccountId(
						project.accountId,
						true
					);
				}

				if (
					[UserRole.DESIGNER, UserRole.ADMIN].includes(user.userRole)
				) {
					return;
				}

				this.editorRepository.setLocked(project.locked);
			});

		this.projectTree$ = combineLatest([
			this.projectsRepository.project$,
			this.partsRepository.parts$,
			this.itemsRepository.items$
		]).pipe(
			takeUntil(this.componentDestroyed$),
			debounceTime(100),
			map(([project, parts]) => {
				if (project?.id) {
					localStorage.setItem('lastVisitedProject', project?.id);
				}

				return {
					...project,
					parts,
				};
			})
		);

		this.itemsRepository.activeItem$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => this.showNewCabinetSelection = {})

		this.configurationControllers = [
			new ConfigurationItem(OutlineTopComponent, 'OUTLINE_TOP'),
			new ConfigurationItem(OutlineFrontComponent, 'OUTLINE_FRONT'),
			new ConfigurationItem(OutlineSideComponent, 'OUTLINE_SIDE'),
			new ConfigurationItem(
				HorizontalDividerComponent,
				'HORIZONTAL_DIVIDER'
			),
			new ConfigurationItem(VerticalDividerComponent, 'VERTICAL_DIVIDER'),
			new ConfigurationItem(DynamicDividerComponent, 'SHELVES'),
			new ConfigurationItem(DoorComponent, 'DOOR'),
			new ConfigurationItem(BackComponent, 'BACK'),
			new ConfigurationItem(CarcaseComponent, 'CARCASE'),
			new ConfigurationItem(ItemComponent, 'ITEM'),
		];
	}

	public ngAfterViewInit() {
		this.shepherdService.modal = true;
		this.shepherdService.confirmCancel = false;
	}

	private addItemsStepsForFirstPart(part: Part): void {
		this.showNewCabinetSelection[part.id] = true;

		setTimeout(() => {
			this.shepherdService.addSteps([
				{
					advanceOn: { selector: '*', event: 'click' },
					text: `Via deze knoppen maak je een nieuwe kast aan of kies je er eentje uit de catalogus`,
					attachTo: {
						element: '.m-project-navigator__select-cabinet__wrapper',
						on: 'right',
					},
					modalOverlayOpeningPadding: 5,
					floatingUIOptions: {
						middleware: [offset(10)],
					},
					id: 'list-selector',
					classes: 'shepherd-info'
				},
			]);
			this.shepherdService.start();
		}, 500)
	}

	addHintToProjectCreateBtn() {
		setTimeout(() => {
			this.shepherdService.addSteps([
				{
					advanceOn: { selector: '*', event: 'click' },
					text: `Voeg een kastengroep toe om te beginnen`,
					attachTo: {
						element: '#a-button-add-project',
						on: 'right',
					},
					modalOverlayOpeningPadding: 5,
					floatingUIOptions: {
						middleware: [offset(10)],
					},
					id: 'add-project',
					classes: 'shepherd-info'
				},
			]);
			this.shepherdService.start();
		}, 500);
	}

	public onSetNewCabinetSelection(partId: string): void {
		this.showNewCabinetSelection[partId] = !this.showNewCabinetSelection[partId] || false;
	}

	private updateQueryParameters = (params: Record<string, string | null | undefined>): void => {
		this.router.navigate([], {
			replaceUrl: true,
			relativeTo: this.route,
			queryParams: {
				...this.route.snapshot.queryParams,
				...params
			},
		});
	};

	// Stop loading when leaving
	public ngOnDestroy(): void {
		try {this.shepherdService.complete();} catch (e) {}

		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	// Get Project with all parts and items
	public getProject(): void {
		const id = this.route.snapshot.params.projectId;
		this.projectsRepository.getProject(id);
	}

	// Show Selected Item Mode
	public selectItem({ item, part }: { item: Item; part: Part }): void {
		this.editorRepository.setDesignerMode(DesignerMode.ITEM);
		this.editorRepository.setIsolatedItem(null);
		this.editorRepository.setTransparency([]);
		this.editorRepository.clearArticles();
		this.editorRepository.setSelectedItems([]);
		this.partsRepository.activatePart(null);
		this.itemsRepository.activateItem(item.id);
	}

	// Show Selected Part Mode
	public selectPart(part: Part): void {
		this.editorRepository.setDesignerMode(DesignerMode.PART);
		this.editorRepository.setSelectedItems([]);
		this.editorRepository.setIsolatedItem(null);
		this.editorRepository.setTransparency([]);
		this.partsRepository.activatePart(part.id);
		this.itemsRepository.activateItem(null);
	}

	// Show Create Item Ribbon
	public createItem(part: Part): void {
		this.partsRepository.activatePart(null);
		this.editorRepository.setDesignerMode(DesignerMode.ITEM);
		this.itemsRepository.items$.pipe(take(1)).subscribe((items) => {
			const item = {
				id: 'new',
				itemType: ItemType.CABINET,
				name: `Kastelement ${zeroPad(
					items.filter((item) => item.partId === part.id).length + 1,
					3
				)}`,
				outline: {
					topOutline: 'OTR',
					sideOutline: 'OSR',
					frontOutline: 'OFR',
					baseIdx: 0,
					fillerIdxs: [],
					dimension: {
						depth: {
							D: { value: 600 }
						},
						height: {
							H: { value: 2500 }
						},
						width: {
							W: { value: 1000 }
						},
					},
				},
				partId: part.id,
				// articles: [
				// 	{
				// 		articleType: ArticleType.FRAME,
				// 	},
				// ],
			} as Partial<Item>;

			this.itemsRepository.createTempNewItem(item);
			this.selectedItem = item;
			this.showNewCabinetSelection[part.id] = false;
		});
	}

	public togglePart(partId: string): void {
		this.openPart = partId;
	}

	// Show Create Part
	public createPart(): void {
		this.editorRepository.setDesignerMode(DesignerMode.PART);
		combineLatest([
			this.projectsRepository.project$,
			this.partsRepository.parts$,
		])
			.pipe(
				take(1),
				switchMap(([project, parts]) =>
					this.partsRepository.createPart({
						projectId: project.id,
						name: `Kastengroep ${zeroPad(parts.length + 1, 3)}`,
					})
				)
			)
			.subscribe((part) => {
				this.itemsRepository.clearItems();
				this.selectPart(part);
				this.openPart = part.id;
				if (this.parts?.length === 1) {
					this.addItemsStepsForFirstPart(part);
				}
			});
	}

	// Go back to project details view
	public toMainView(): void {
		// Reset Everything
		this.selectedIndex = null;
		this.part = null;
		this.selectedItem = null;
		this.editorRepository.setDesignerMode(DesignerMode.PART);
	}

	private selectDesignerModeByRoute(url: string): void {
		if (!url) {
			return;
		}
		if (url.includes('cutting-lists')) {
			this.editorRepository.setDesignerMode(DesignerMode.CUTTING_LIST);
		} else if (url.includes('editor') && !url.includes('item')) {
			this.editorRepository.setDesignerMode(DesignerMode.PART);
		} else if (url.includes('editor') && url.includes('item')) {
			this.editorRepository.setDesignerMode(DesignerMode.ITEM);
		}
	}
}
